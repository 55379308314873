<template>
  <section
    class="invoice-preview-wrapper"
    style="position: relative"
  >
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="estimate === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'estimate-list' }"
        >
          invoice List
        </b-link>
        for other invoice.
      </div>
    </b-alert>

    <b-row
      v-if="estimate"
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >

          <div class="mt-1">
            <b-row style="color: black">
              <b-col
                cols="8"
                md="8"
                sm="12"
              >
                <div
                  style="color: black; font-size: 40px"
                  class="mt-1 font-weight-bolder"
                >
                  Radhia Khalfallah <br>

                  <b-card-text
                    style="font-size: 20px"
                    class="font-weight-bold mt-1"
                  >
                    <div
                      style="color: black; font-size: 28px"
                      class="font-weight-bolder"
                    >
                      Clé minute
                    </div>
                    <div class="mt-1">
                      AV.PLACEHACHED NABEUL
                    </div>

                    <div class="mt-1 mb-1">
                      MF : 386475Y/N/C/000
                    </div>
                  </b-card-text>
                </div>
              </b-col>
              <b-col
                v-if="estimate.customer"
                cols="4"
                md="4"
                sm="12"
              >
                <!-- Header: Left Content -->
                <div style="color: black; font-size: 20px">

                  <h3
                    class="font-weight-bolder"
                    style="
                    color: black;
                    font-size: 28px;
                    padding: 10px;
                    text-align: center;
                  "
                  >
                    DEVIS N° {{ estimate.number }}
                  </h3>
                  <h4
                    class="font-weight-bolder"
                    style="
                    color: black;
                    font-size: 22px;
                    padding: 10px;
                    text-align: center;
                  "
                  >
                    Date: {{ estimate.date }}
                  </h4>
                </div>
                <!-- Header: Right Content -->
                <!-- Header: Right Content -->
              </b-col>
            </b-row>
          </div>

          <hr>
          <div>
            <b-row style="color: black">

              <b-col
                v-if="estimate.customer"
                cols="6"
                md="6"
                sm="12"
                style="
                  font-size: 18px;
                  padding: 10px;
                "
              >
                <!-- Header: Left Content -->
                <div style="color: black; font-size: 20px">
                  <h4
                    style="color: black; font-size: 20px"
                    class="mb-1 font-weight-bolder"
                  >
                    Client: {{ estimate.customer.company_name }}
                  </h4>
                  <span class="mb-0 mt-1 font-weight-bold">
                    Adresse: {{ estimate.customer.address }}
                  </span>
                  <b-card-text
                    v-if="estimate.customer.tax_identification_number"
                    class="mb-0 mt-1 font-weight-bold"
                  >
                    MF : {{ estimate.customer.tax_identification_number }}
                  </b-card-text>
                  <b-card-text
                    v-else
                    class="mb-0 mt-1 font-weight-bold"
                  >
                    CIN : {{ estimate.customer.cin }}
                  </b-card-text>
                </div>

                <!-- Header: Right Content -->
                <!-- Header: Right Content -->
              </b-col>

              <b-col
                cols="6"
                md="6"
                sm="12"
              />
            </b-row>
          </div>

          <!-- Invoice Description: Table -->
          <table class="container-products mt-2">
            <b-table-lite
              :small="true"
              responsive
              :items="p"
              :fields="[
                'N°',
                'Réf',
                'Désignation',
                'Qté',
                'Prix U.H.T',
                'Total H.T',
                'Taux TVA',
                'Prix U.TTC',
                'Total TTC',
              ]"
              class="ta font-weight-bold mt-1"
              :tbody-class="tableCss"
              :tbody-tr-attr="trStyle"
            />
          </table>

          <!-- Invoice Description: Total -->

          <div class="pagebreak">

            <div
              style="font-size: 22px"
            >
              <table
                v-if="estimate"
                class="table-design"
              >
                <tr>
                  <th class="table-design">
                    Total HT
                  </th>
                  <th class="table-design">
                    Total Taxes
                  </th>
                  <th class="table-design">
                    Timbre
                  </th>
                  <th class="table-design">
                    Total TTC
                  </th>
                </tr>
                <tr>
                  <td
                    v-if="estimate"
                    class="table-design"
                  >
                    {{ estimate.price_ht }}
                  </td>
                  <td
                    v-else
                    class="table-design"
                  >
                    0
                  </td>
                  <td
                    v-if="estimate"
                    class="table-design"
                  >
                    {{ estimate.tax }}
                  </td>
                  <td
                    v-else
                    class="table-design"
                  >
                    0
                  </td>
                  <td
                    class="table-design"
                  >
                    1
                  </td>
                  <td
                    v-if="estimate"
                    class="table-design"
                  >
                    {{ estimate.price_ttc }}
                  </td>
                  <td
                    v-else
                    class="table-design"
                  >
                    0
                  </td>
                </tr>
              </table>
              <div class="font-weight-bold  mt-1 color:black">
                {{ lettre }}
              </div>
            </div>
            <b-row class="card-body text-center">
              <b-col
                cols="6"
                md="6"
                xl="6"
                sm="12"
              >
                <h5 style="color: black; font-size: 16px">
                  Signature
                </h5>
              </b-col>
              <b-col
                cols="6"
                md="6"
                xl="6"
                sm="12"
              >
                <h5 style="color: black; font-size: 16px">
                  La durée de validité accordée à ce devis est de 2 mois à partir du date de création
                </h5>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Print
          </b-button>

          <!-- Button: Edit -->
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import axiosIns from '@/libs/axios'

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { NumberToLetter } from 'convertir-nombre-lettre'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
  },
  data() {
    return {
      p: [],
      estimate: [],
      lettre: '',
    }
  },

  created() {
    this.id = this.$route.params.id
    this.getEstimate()
    this.getProducts()
  },
  methods: {
    trStyle() {
      return {
        height: '70px',
      }
    },
    tableCss() {
      return {
        height: '800px',
      }
    },
    format(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    async getEstimate() {
      const { data } = await axiosIns.get(`/estimates/details/${this.id}/`)
      this.estimate = data
      const sommeString = this.estimate.price_ttc.toString()
      if (sommeString.includes('.000') === true) {
        this.lettre = `${NumberToLetter(this.estimate.price_ttc)} Dinars `
      } else {
        const [dinar, milim] = this.estimate.price_ttc.split('.')
        this.lettre = `${NumberToLetter(dinar)} Dinars , ${NumberToLetter(
          milim,
        )} `
      }
    },

    async getProducts() {
      const { data } = await axiosIns.post('/estimates-products/details/', {
        estimate: this.id,
      })
      for (let i = 0; i < data.length; i += 1) {
        this.addnewitem(
          i + 1,
          data[i].reference,
          data[i].label,
          data[i].quantity,
          data[i].price_ht,
          data[i].total_ht,
          data[i].vat,
          data[i].price_ttc,
          data[i].total_ttc,
        )
      }
    },

    // eslint-disable-next-line camelcase
    addnewitem(
      nb,
      reference,
      label,
      quantity,
      price_ht,
      total_ht,
      tax,
      price_ttc,
      total_ttc,
    ) {
      this.p.push({
        Réf: reference,
        Désignation: label,
        Qté: quantity,
        'Prix U.H.T': price_ht,
        'Total H.T': total_ht,
        'Taux TVA': tax,
        'Prix U.TTC': price_ttc,
        'Total TTC': total_ttc,
        'N°': nb,
      })
    },
  },
  setup() {
    const printInvoice = () => {
      window.print()
    }

    return {
      printInvoice,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-invoice.scss";
.table-design {
  padding: 10px;
  text-align: left;
  border: 1px solid black;
  }
.trborder {
  border-bottom: 0px solid black;
}
footer {
  bottom: 0;
  height: auto; /* Footer height */
}
.container-products{
  min-height: 600px;
  border: 1px solid black;
}
table {
  width: 100%;
  border-collapse: collapse;
}
.mycolor {
  background-color: #208a9d;
}
.contact {
  background-color: #208a9d;
  padding: 10px;
  border-radius: 20px;
}
@media print {
  @page {
    size: A4;
  }
  html, body {
    height: 99%;
  }
  .pagebreak {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  .ta {
    overflow-x: visible;
    font-size: 18px;
    color: black;
  }

  footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto; /* Footer height */
  }

  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  .mycolor {
    background-color: #208a9d;
    -webkit-print-color-adjust: exact;
  }
  .contact {
    background-color: #208a9d;
    padding: 20px;
    border-radius: 25px;
    -webkit-print-color-adjust: exact;
  }
  footer footer-light footer-static {
    display: inline-block;
  }
  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  table {
    width: 100%;
  }
  * {
    color: black !important;
  }

  .border-pricing {
    text-align: left;
    border: 0px solid black;
  }
  .table-design {
    padding: 10px;
    text-align: left;
    border: 1px solid black;
  }
}
</style>
